import React from 'react';
import Layout from '../components/Layout';

const ContactUs = () => {
  return (
    <Layout>
      <div style={styles.pageContainer}>
        <h1 style={styles.heading}>Contact Us</h1>
        <p style={styles.text}>For any inquiries or support, please reach out to us:</p>
        <p style={styles.text}><strong>Phone:</strong> +91 [6398896398]</p>
        <p style={styles.text}><strong>Email:</strong> info@gemmanagers.com</p>
        <p style={styles.text}><strong>Address:</strong> Skyseeker Research Pvt. Ltd, 74/II, Opp. Gate no. 2, C-Cross Road, Mumbai- 400093</p>
      </div>
    </Layout>
  );
};

const styles = {
  pageContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
  },
};

export default ContactUs;
