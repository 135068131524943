import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import { auth } from '../firebaseConfig';
import { useUserContext } from '../UserContext';

function NavBar() {
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [userDropdownTimeout, setUserDropdownTimeout] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isLoggedIn, isSubscribed, checkSubscriptionStatus } = useUserContext();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await checkSubscriptionStatus();
      }
    });
    return () => unsubscribe();
  }, [checkSubscriptionStatus]);

  const handleLogout = () => {
    auth.signOut();
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Updated desktop user dropdown handlers with delay
  const handleUserMouseEnter = () => {
    if (userDropdownTimeout) {
      clearTimeout(userDropdownTimeout);
      setUserDropdownTimeout(null);
    }
    setShowUserDropdown(true);
  };

  const handleUserMouseLeave = () => {
    const timeout = setTimeout(() => {
      setShowUserDropdown(false);
    }, 300); // 300ms delay; adjust if needed
    setUserDropdownTimeout(timeout);
  };

  return (
    <nav className="navbar">
      {/* ========== Desktop Navigation ========== */}
      <div className="desktop-nav">
        <div className="desktop-container">
          <div className="desktop-left">
            <Link to="/" className="logo-link">
              <img 
                src={`${process.env.PUBLIC_URL}/images/tm logo background 1e2a38.png`} 
                alt="Tender Managers Logo" 
                className="logo" 
              />
              <div className="brand-text">
                <div className="brand-title">Tender Managers</div>
                <div className="brand-tagline">Your Partners in Growth</div>
              </div>
            </Link>

            {isLoggedIn && isSubscribed ? (
              <Link to="/tender-selection" className="tender-tracker-btn">
                Tracker Dashboard
              </Link>
            ) : (
              <div className="tender-tracker-btn empty-space"></div>
            )}

            <ul className="nav-links">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li 
                className="nav-item resources-dropdown" 
                onMouseEnter={() => setShowResourcesDropdown(true)} 
                onMouseLeave={() => setShowResourcesDropdown(false)}
              >
                <span className="nav-link">Resources</span>
                {showResourcesDropdown && (
                  <ul className="resources-dropdown-menu">
                    <li><Link to="/resources/government-portals">Government Portals</Link></li>
                    <li><Link to="/resources/procurement-rules">Procurement Rules</Link></li>
                    <li><Link to="/resources/govt-orgs-and-depts">Govt Orgs & Depts</Link></li>
                    <li><Link to="/resources/gem-categories">GeM Categories</Link></li>
                  </ul>
                )}
              </li>
              <li><Link to="/subscription-pricing">Subscription Pricing</Link></li>
              <li><Link to="/reports-pricing">Reports Pricing</Link></li>
            </ul>
          </div>
          <div className="desktop-right">
            {isLoggedIn ? (
              <div
                className="user-dropdown"
                onMouseEnter={handleUserMouseEnter}
                onMouseLeave={handleUserMouseLeave}
              >
                <div className="user-icon-container">
                  <img 
                    src={`${process.env.PUBLIC_URL}/images/user-icon.png`} 
                    alt="User Icon" 
                    className="user-icon" 
                  />
                </div>
                {showUserDropdown && (
                  <ul className="user-dropdown-menu">
                    <li><Link to="/my-profile">My Profile</Link></li>
                    <li><Link to="/active-plan">Active Plan</Link></li>
                    <li><Link to="/favorite-tenders">Favorite Tenders</Link></li>
                    <li><Link to="/reports">Reports</Link></li>
                    <li>
                      <button onClick={handleLogout} className="logout-button">
                        Logout
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              <Link to="/login" className="login-btn">Login</Link>
            )}
          </div>
        </div>
      </div>

      {/* ========== Mobile Navigation ========== */}
      <div className="mobile-nav">
        <div className="mobile-container">
          <button className="hamburger-menu" onClick={toggleMobileMenu}>
            &#9776;
          </button>
          <Link to="/" className="mobile-logo" onClick={closeMobileMenu}>
            <img 
              src={`${process.env.PUBLIC_URL}/images/tm logo background 1e2a38.png`} 
              alt="Tender Managers Logo" 
              className="logo-small" 
            />
          </Link>
          {isLoggedIn && isSubscribed && (
            <Link to="/tender-selection" className="tender-tracker-btn mobile-dashboard" onClick={closeMobileMenu}>
              Tracker Dashboard
            </Link>
          )}
          {isLoggedIn ? (
            <div className="user-dropdown mobile-user">
              <div className="user-icon-container" onClick={() => setShowUserDropdown(!showUserDropdown)}>
                <img 
                  src={`${process.env.PUBLIC_URL}/images/user-icon.png`} 
                  alt="User Icon" 
                  className="user-icon" 
                />
              </div>
              {showUserDropdown && (
                <ul className="user-dropdown-menu mobile-user-dropdown">
                  <li><Link to="/my-profile" onClick={closeMobileMenu}>My Profile</Link></li>
                  <li><Link to="/active-plan" onClick={closeMobileMenu}>Active Plan</Link></li>
                  <li><Link to="/favorite-tenders" onClick={closeMobileMenu}>Favorite Tenders</Link></li>
                  <li><Link to="/reports" onClick={closeMobileMenu}>Reports</Link></li>
                  <li>
                    <button onClick={handleLogout} className="logout-button">
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <Link to="/login" className="login-btn mobile-login" onClick={closeMobileMenu}>
              Login
            </Link>
          )}
        </div>
        {mobileMenuOpen && (
          <div className="mobile-nav-menu">
            <ul>
              <li><Link to="/about" onClick={closeMobileMenu}>About Us</Link></li>
              <li><Link to="/services" onClick={closeMobileMenu}>Services</Link></li>
              <li className="nav-item resources-dropdown">
                <span className="nav-link" onClick={() => setShowResourcesDropdown(!showResourcesDropdown)}>
                  Resources
                </span>
                {showResourcesDropdown && (
                  <ul className="resources-dropdown-menu mobile-resources-dropdown">
                    <li><Link to="/resources/government-portals" onClick={closeMobileMenu}>Government Portals</Link></li>
                    <li><Link to="/resources/procurement-rules" onClick={closeMobileMenu}>Procurement Rules</Link></li>
                    <li><Link to="/resources/govt-orgs-and-depts" onClick={closeMobileMenu}>Govt Orgs & Depts</Link></li>
                    <li><Link to="/resources/gem-categories" onClick={closeMobileMenu}>GeM Categories</Link></li>
                  </ul>
                )}
              </li>
              <li><Link to="/subscription-pricing" onClick={closeMobileMenu}>Subscription Pricing</Link></li>
              <li><Link to="/reports-pricing" onClick={closeMobileMenu}>Reports Pricing</Link></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
