import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout"; // Import the Layout component
import "./ReportsPricing.css";

const ReportsPricing = function () {
  const navigate = useNavigate();

  const handleGemSelection = function () {
    navigate("/gem-selection");
  };
  const handleMinistrySelection = function () {
    navigate("/ministries-selection");
  };
  const handleStateSelection = function () {
    navigate("/states-selection");
  };

  return React.createElement(
    Layout,
    null, // Wrap the content inside Layout
    React.createElement(
      "div",
      { className: "reports-pricing-container" },
      React.createElement("h1", null, "Request a Report"),
      React.createElement(
        "p",
        null,
        "Our reports are priced dynamically based on the level and depth of analysis—this varies with categories, ministries, or states. For example, you may review our sample reports for ",
        React.createElement("b", null, "Computer Printers (Category-Level)"),
        " and ",
        React.createElement("b", null, "Punjab (State-Level)"),
        ". Report requests are processed within 24-48 hours (or sometimes longer, depending on complexity). After preliminary checks, a payment link will be shared with you. Once payment is confirmed, the report will be made available. In unique cases where a request is not feasible, you will be notified accordingly."
      ),
      React.createElement("h3", null, "Sample Reports"),
      React.createElement(
        "ul",
        { className: "sample-reports-list" },
        React.createElement(
          "li",
          null,
          React.createElement(
            "a",
            {
              href: "/sample_reports/product_report_Computer Printers (01.04.2023-30.11.2024) Processed Data.xlsx",
              download: "product_report_Computer Printers (01.04.2023-30.11.2024) Processed Data.xlsx"
            },
            "📄 Download Sample: Computer Printers Report"
          )
        ),
        React.createElement(
          "li",
          null,
          React.createElement(
            "a",
            {
              href: "/sample_reports/report_Punjab (01.04.2023-28.11.2024).xlsx",
              download: "report_Punjab (01.04.2023-28.11.2024).xlsx"
            },
            "📄 Download Sample: Punjab Report"
          )
        )
      ),
      React.createElement("h3", null, "Submit a Request"),
      React.createElement(
        "p",
        null,
        "To request a report, please click on one of the buttons below to select your criteria:"
      ),
      React.createElement(
        "div",
        { className: "reports-buttons" },
        React.createElement(
          "button",
          { onClick: handleGemSelection },
          "GeM Categories"
        ),
        React.createElement(
          "button",
          { onClick: handleMinistrySelection },
          "Ministries"
        ),
        React.createElement(
          "button",
          { onClick: handleStateSelection },
          "States"
        )
      )
    )
  );
};

export default ReportsPricing;
