import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button } from '@mui/material';
import moment from 'moment';
import Layout from './components/Layout';
import { db, auth } from './firebaseConfig'; // Firebase setup
import { doc, setDoc, deleteDoc, getDocs, collection, writeBatch } from 'firebase/firestore';
import Highlighter from 'react-highlight-words'; // <-- Import for highlighting
import './CentralCPPPTenders.css'; // Local CSS for this component only

function CentralCPPPTenders() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState('');
  const [sortModel, setSortModel] = useState([{ field: 'SNo', sort: 'asc' }]);
  const [customPage, setCustomPage] = useState('');
  const [favorites, setFavorites] = useState([]);

  // Create search keywords array for highlighting
  const searchKeywords = search.split(',').map(word => word.trim()).filter(Boolean);

  const fetchFavorites = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;
    const favCollectionRef = collection(db, 'users', userId, 'favoriteCentralTenders');
    const favDocs = await getDocs(favCollectionRef);
    const favData = favDocs.docs.map(doc => doc.id);
    setFavorites(favData);
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  const fetchData = useCallback(() => {
    const params = new URLSearchParams({
      page: page + 1,
      limit: pageSize,
      search,
      sortField: sortModel[0]?.field || 'SNo',
      sortOrder: sortModel[0]?.sort || 'asc',
    });

    const apiUrl = process.env.REACT_APP_API_URL || 'https://webapptest1-783744cf01e6.herokuapp.com';

    fetch(`${apiUrl}/api/central-cppp-data?${params.toString()}`)
      .then(response => response.json())
      .then(result => {
        setData(result.data);
        setTotal(result.total);
      })
      .catch(error => console.error('Error:', error));
  }, [page, pageSize, search, sortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };
  const handleSortModelChange = (model) => setSortModel(model);
  const handleCustomPageChange = () => {
    const parsedPage = parseInt(customPage, 10);
    const maxPage = Math.ceil(total / pageSize) - 1;
    if (parsedPage >= 1 && parsedPage <= maxPage + 1) {
      setPage(parsedPage - 1);
    } else {
      alert(`Please enter a page between 1 and ${maxPage + 1}`);
    }
  };

  const sanitizeTenderID = (tenderID) => tenderID.replace(/\//g, '-');

  const toggleFavorite = async (tender) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        alert('Please log in to mark favorites.');
        return;
      }

      const sanitizedTenderID = sanitizeTenderID(tender.TenderID);
      const favRef = doc(db, 'users', userId, 'favoriteCentralTenders', sanitizedTenderID);

      if (favorites.includes(sanitizedTenderID)) {
        await deleteDoc(favRef);
        setFavorites(prev => prev.filter(id => id !== sanitizedTenderID));
      } else {
        if (favorites.length >= 15) {
          alert('You can only mark up to 15 favorites.');
          return;
        }
        await setDoc(favRef, {
          tenderID: tender.TenderID,
          title: tender.Title,
          organization: tender.OrganizationName,
          portalLink: tender.PortalLink,
          markedDate: new Date(),
        });
        setFavorites(prev => [...prev, sanitizedTenderID]);
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  // Bulk Unmark All for Central favorites
  const unmarkAllCentralFavorites = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || favorites.length === 0) return;
      const batch = writeBatch(db);
      favorites.forEach((id) => {
        batch.delete(doc(db, 'users', userId, 'favoriteCentralTenders', id));
      });
      await batch.commit();
      setFavorites([]);
    } catch (error) {
      console.error('Error unmarking all central favorites:', error);
    }
  };

  const columns = [
    {
      field: 'favoriteAction',
      headerName: 'Favorite',
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => toggleFavorite(params.row)}
          className={`favorite-button ${favorites.includes(sanitizeTenderID(params.row.TenderID)) ? 'unmark' : ''}`}
        >
          {favorites.includes(sanitizeTenderID(params.row.TenderID)) ? 'Unmark' : 'Favorite'}
        </button>
      ),
    },
    { field: 'SNo', headerName: 'S No', width: 90 },
    // Highlight Title/Ref. No./Tender ID
    {
      field: 'Title',
      headerName: 'Title/Ref. No./Tender ID',
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <div className="multiline-text">
          <Highlighter
            searchWords={searchKeywords}
            textToHighlight={params.value || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </div>
      ),
    },
    // Highlight Organisation Name
    {
      field: 'OrganizationName',
      headerName: 'Organisation Name',
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <div className="multiline-text">
          <Highlighter
            searchWords={searchKeywords}
            textToHighlight={params.value || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </div>
      ),
    },
    // Highlight Tender ID
    {
      field: 'TenderID',
      headerName: 'Tender ID',
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <div className="multiline-text">
          <Highlighter
            searchWords={searchKeywords}
            textToHighlight={params.value || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </div>
      ),
    },
    // Highlight Portal Link (inside an anchor tag)
    {
      field: 'PortalLink',
      headerName: 'Portal Link',
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer" className="multiline-link">
          <Highlighter
            searchWords={searchKeywords}
            textToHighlight={params.value || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </a>
      ),
    },
    {
      field: 'ePublishedDate',
      headerName: 'Published Date',
      width: 200,
      valueGetter: (params) => moment(params.row?.ePublishedDate).format('DD-MM-YYYY hh:mm A'),
    },
    {
      field: 'BidSubmissionClosingDate',
      headerName: 'Bid Submission Closing Date',
      width: 200,
      valueGetter: (params) => moment(params.row?.BidSubmissionClosingDate).format('DD-MM-YYYY hh:mm A'),
    },
  ];

  const totalPages = Math.ceil(total / pageSize);
  const currentPage = page + 1;

  return (
    <Layout>
      <div className="central-tenders-container">
        <h1>Central CPPP Tenders</h1>
        <h3>Central Govt Tenders Updated as on 24.03.2025</h3>

        {/* Combined Row for Search, Go to Page, and Unmark All */}
        <div className="search-controls" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div className="search-row" style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem' }}>
            <TextField
              label="Search"
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
              style={{ width: '300px' }}
              helperText="Enter keywords separated by commas (e.g., construction, AMC, Repair)"
              className="search-box"
            />
            <div>
              <TextField
                label="Go to Page"
                variant="outlined"
                value={customPage}
                onChange={(e) => setCustomPage(e.target.value)}
                style={{ marginRight: '0.5rem', width: '150px' }}
                className="goto-page-box"
              />
              <Button variant="contained" onClick={handleCustomPageChange}>Go</Button>
              <p style={{ margin: '0.5rem 0 0' }}>
                Page {page + 1} of {Math.ceil(total / pageSize)} (Showing {pageSize} entries per page)
              </p>
            </div>
          </div>
          <Button variant="contained" className="unmark-button" onClick={unmarkAllCentralFavorites}>
            Unmark All Central Favorites
          </Button>
        </div>

        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            rows={data}
            columns={columns}
            rowCount={total}
            pagination
            paginationMode="server"
            page={page}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[25, 50, 100]}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowId={(row) => row._id || row.SNo}
            autoHeight
            getRowHeight={() => 'auto'}
          />
        </div>
      </div>
    </Layout>
  );
}

export default CentralCPPPTenders;
