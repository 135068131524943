// src/components/ClientCarousel.js
import React from 'react';
import './ClientCarousel.css'; // Styling for the carousel

function ClientCarousel() {
    const clients = [
        { name: 'Tata Motors', logo: '/images/tata-motors-logo.jpg' },
        { name: 'Trueview', logo: '/images/trueview-logo.png' },
        { name: 'Elanpro', logo: '/images/elanpro-logo.png' },
        // Add more as needed
    ];

    return (
        <div className="carousel-container">
            <h2>Our Clients</h2>
            <div className="carousel">
                {clients.map((client, index) => (
                    <div key={index} className="carousel-item">
                        <img src={client.logo} alt={client.name} className="client-logo" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ClientCarousel;
