import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import emailjs from "emailjs-com";
import { auth } from "../firebaseConfig"; // Import auth to access current user
import "./ReportDetailsPage.css";

const GemCategoriesDetailsPage = function () {
  const location = useLocation();
  const selectedItems = (location.state && location.state.selectedItems) || [];
  const analysisTypes = [
    "Office Level Analysis of the Category",
    "Brand Analysis of the Category",
    "Office-Brand Link Analysis",
    "Brand-Model Analysis",
    "Office-Brand-Avg. Price Link Analysis",
    "Office-Product High-Low Analysis",
    "Monthly Trends Analysis",
    "Outlier Analysis"
  ];

  const [details, setDetails] = useState(
    selectedItems.reduce((acc, item) => {
      acc[item] = { timePeriod: "", analysisType: [], customInstructions: "" };
      return acc;
    }, {})
  );

  const handleTimePeriodChange = (item, value) => {
    setDetails((prev) => ({
      ...prev,
      [item]: { ...prev[item], timePeriod: value }
    }));
  };

  const handleAnalysisChange = (item, value) => {
    setDetails((prev) => {
      const updatedAnalysis = prev[item].analysisType.includes(value)
        ? prev[item].analysisType.filter((type) => type !== value)
        : [...prev[item].analysisType, value];
      return { ...prev, [item]: { ...prev[item], analysisType: updatedAnalysis } };
    });
  };

  const handleCustomInstructionsChange = (item, value) => {
    setDetails((prev) => ({
      ...prev,
      [item]: { ...prev[item], customInstructions: value }
    }));
  };

  const handleSubmit = async () => {
    try {
      const user = auth.currentUser; // Get current user info
      const userPhone = user ? user.phoneNumber : "Unknown User";

      const emailContent = {
        user_phone: userPhone,
        message: JSON.stringify(details, null, 2)
      };

      const response = await emailjs.send(
        "service_mzh7l78",        // Service ID
        "template_4sk1mtn",       // Template ID
        emailContent,
        "Gn0MDW2Jf_0woVgRR"      // Public Key
      );

      console.log("Email sent successfully:", response.status, response.text);
      alert("Your GeM Categories report request has been submitted!");
    } catch (error) {
      console.error("Failed to send email:", error);
      alert("Failed to submit the request. Please try again.");
    }
  };

  const detailsItems = selectedItems.map((item, index) => (
    <div key={index} className="report-item">
      <h3>{item}</h3>
      <label>Select Time Period</label>
      <select onChange={(e) => handleTimePeriodChange(item, e.target.value)}>
        <option value="">Select</option>
        <option value="3 months">Last 3 months</option>
        <option value="6 months">Last 6 months</option>
        <option value="12 months">Last 12 months</option>
      </select>
      <label>Select Analysis Type</label>
      {analysisTypes.map((type, idx) => (
  <div key={idx} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <input
      type="checkbox"
      value={type}
      onChange={() => handleAnalysisChange(item, type)}
      checked={details[item].analysisType.includes(type)}
      style={{ width: "16px", height: "16px", margin: "0" }} // Ensures checkbox size is consistent
    />
    <label style={{ cursor: "pointer", fontSize: "16px", margin: "0" }}>{type}</label>
  </div>
))}

      <label>Custom Instructions</label>
      <textarea
        value={details[item].customInstructions}
        onChange={(e) => handleCustomInstructionsChange(item, e.target.value)}
        placeholder="Enter any custom instructions here..."
      />
    </div>
  ));

  return (
    <Layout>
      <div className="report-details-container">
        <h1>Provide Additional Details for GeM Categories</h1>
        {detailsItems}
        <button onClick={handleSubmit}>Submit Request</button>
      </div>
    </Layout>
  );
};

export default GemCategoriesDetailsPage;
