import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './ProcurementRules.css';

const ProcurementRules = () => {
  const [procurementData, setProcurementData] = useState([]);

  useEffect(() => {
    fetch('/procurement_rules.json')
      .then((response) => response.json())
      .then((data) => setProcurementData(data))
      .catch((error) => console.error('Error loading procurement data:', error));
  }, []);

  return (
    <div className="page-container">
      <NavBar />
      <main className="content-container">
        <h1>Procurement Rules</h1>
        <table className="procurement-table">
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>S No.</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Subject</th>
              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Reference</th>
            </tr>
          </thead>
          <tbody>
            {procurementData.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{item['S No.']}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{item['Subject']}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  <a href={item['Reference']} target="_blank" rel="noopener noreferrer">
                    {item['Reference']}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
      <Footer />
    </div>
  );
};

export default ProcurementRules;
