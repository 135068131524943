import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDHHpswrqLXkzIPEEdwdAgFREu9oRfrJH8",
  authDomain: "tendermanagers-5aa29.firebaseapp.com",
  projectId: "tendermanagers-5aa29",
  storageBucket: "tendermanagers-5aa29.appspot.com",
  messagingSenderId: "257573478295",
  appId: "1:257573478295:web:af1fcbcdcd2d1a2e4deed2",
  measurementId: "G-Z9KP5Y99CM"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = getFirestore(app);
firebase.analytics();

export { firebase, auth, db };
