import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import Layout from './Layout'; 
import './FavoriteTenders.css';

const FavoriteTenders = () => {
  const [gemFavorites, setGemFavorites] = useState([]);
  const [centralFavorites, setCentralFavorites] = useState([]);
  const [stateFavorites, setStateFavorites] = useState([]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        // Fetch GeM Favorites
        const gemDocs = await getDocs(collection(db, 'users', userId, 'favoriteGeMTenders'));
        setGemFavorites(gemDocs.docs.map((doc) => doc.data()));

        // Fetch Central CPPP Favorites
        const centralDocs = await getDocs(collection(db, 'users', userId, 'favoriteCentralTenders'));
        setCentralFavorites(centralDocs.docs.map((doc) => doc.data()));

        // Fetch State CPPP Favorites
        const stateDocs = await getDocs(collection(db, 'users', userId, 'favoriteStateTenders'));
        setStateFavorites(stateDocs.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error('Error fetching favorite tenders:', error);
      }
    };

    fetchFavorites();
  }, []);

  // --- Bulk Unmark Functions ---

  const removeAllGemFavorites = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || gemFavorites.length === 0) return;

      const batch = writeBatch(db);
      gemFavorites.forEach((tender) => {
        const docId = tender.bidNumber.replace(/\//g, '-');
        batch.delete(doc(db, 'users', userId, 'favoriteGeMTenders', docId));
      });
      await batch.commit();
      setGemFavorites([]);
    } catch (error) {
      console.error('Error removing all GeM favorites:', error);
    }
  };

  const removeAllCentralFavorites = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || centralFavorites.length === 0) return;

      const batch = writeBatch(db);
      centralFavorites.forEach((tender) => {
        batch.delete(doc(db, 'users', userId, 'favoriteCentralTenders', tender.tenderID));
      });
      await batch.commit();
      setCentralFavorites([]);
    } catch (error) {
      console.error('Error removing all Central favorites:', error);
    }
  };

  const removeAllStateFavorites = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || stateFavorites.length === 0) return;

      const batch = writeBatch(db);
      stateFavorites.forEach((tender) => {
        batch.delete(doc(db, 'users', userId, 'favoriteStateTenders', tender.tenderID));
      });
      await batch.commit();
      setStateFavorites([]);
    } catch (error) {
      console.error('Error removing all State favorites:', error);
    }
  };

  return (
    <Layout>
      <div className="favorites-container">
        <h1>Favorite Tenders</h1>

        {/* GeM Favorites Section */}
        <div className="favorites-section">
          <h2>GeM Favorites</h2>
          {gemFavorites.length ? (
            <>
              <button onClick={removeAllGemFavorites}>
                Unmark All GeM Favorites
              </button>
              <ul>
                {gemFavorites.map((tender, index) => (
                  <li key={index} className="tender-card">
                    <p><strong>Bid Number:</strong> {tender.bidNumber}</p>
                    <p><strong>Items:</strong> {tender.items}</p>
                    <p><strong>Quantity:</strong> {tender.quantity}</p>
                    <p><strong>District:</strong> {tender.district}</p>
                    <p><strong>Bid Type:</strong> {tender.bidType}</p>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="no-favorites">No GeM favorites found.</p>
          )}
        </div>

        {/* Central CPPP Favorites Section */}
        <div className="favorites-section">
          <h2>Central CPPP Favorites</h2>
          {centralFavorites.length ? (
            <>
              <button onClick={removeAllCentralFavorites}>
                Unmark All Central Favorites
              </button>
              <ul>
                {centralFavorites.map((tender, index) => (
                  <li key={index} className="tender-card">
                    <p><strong>Tender ID:</strong> {tender.tenderID}</p>
                    <p><strong>Title:</strong> {tender.title}</p>
                    <p><strong>Organization:</strong> {tender.organization}</p>
                    <p>
                      <strong>Portal Link:</strong>{' '}
                      <a href={tender.portalLink} target="_blank" rel="noopener noreferrer">
                        {tender.portalLink}
                      </a>
                    </p>
                    <p>
                      <strong>Marked Date:</strong>{' '}
                      {new Date(tender.markedDate.seconds * 1000).toLocaleString()}
                    </p>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="no-favorites">No Central CPPP favorites found.</p>
          )}
        </div>

        {/* State CPPP Favorites Section */}
        <div className="favorites-section">
          <h2>State CPPP Favorites</h2>
          {stateFavorites.length ? (
            <>
              <button onClick={removeAllStateFavorites}>
                Unmark All State Favorites
              </button>
              <ul>
                {stateFavorites.map((tender, index) => (
                  <li key={index} className="tender-card">
                    <p><strong>Tender ID:</strong> {tender.tenderID}</p>
                    <p><strong>Title:</strong> {tender.title}</p>
                    <p><strong>State:</strong> {tender.state}</p>
                    <p>
                      <strong>Portal Link:</strong>{' '}
                      <a href={tender.portalLink} target="_blank" rel="noopener noreferrer">
                        {tender.portalLink}
                      </a>
                    </p>
                    <p>
                      <strong>Marked Date:</strong>{' '}
                      {new Date(tender.markedDate.seconds * 1000).toLocaleString()}
                    </p>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="no-favorites">No State CPPP favorites found.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default FavoriteTenders;
