import React from 'react';
import Layout from '../components/Layout';

const RefundPolicy = () => {
  return (
    <Layout>
      <div style={styles.pageContainer}>
        <h1 style={styles.heading}>Refund and Cancellation Policy</h1>
        <p style={styles.text}><strong>Last updated:</strong> 22nd February 2025</p>
        <p style={styles.text}><strong>1. General Refunds:</strong> Refunds are only applicable if data updates are not provided for 10 consecutive days.</p>
        <p style={styles.text}><strong>2. Reports:</strong> Refunds are not applicable for reports.</p>
        <p style={styles.text}><strong>3. Cancellation:</strong> You may cancel anytime, but refunds for remaining terms are not provided.</p>
        <p style={styles.text}><strong>4. Processing:</strong> Refunds, if applicable, may take several business days to process.</p>
      </div>
    </Layout>
  );
};

const styles = {
  pageContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
  },
};

export default RefundPolicy;
