import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout"; // Import the Layout component
import centralData from "./data/central_data.json";
import "./ReportSelectionPage.css";

const MinistriesSelectionPage = function () {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Get unique ministries from centralData and filter by search term
  const getFilteredData = function () {
    var data = Array.from(
      new Set(
        centralData
          .map(function (item) {
            return item.Ministry;
          })
          .filter(function (val) {
            return Boolean(val);
          })
      )
    );
    return data.filter(function (item) {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const handleCheckboxChange = function (item) {
    setSelectedItems(function (prev) {
      return prev.includes(item)
        ? prev.filter(function (i) {
            return i !== item;
          })
        : prev.concat(item);
    });
  };

  const handleNext = function () {
    if (selectedItems.length === 0) {
      alert("Please select at least one option.");
      return;
    }
    navigate("/ministries-details", { state: { selectedItems: selectedItems } });
  };

  var filteredData = getFilteredData();
  var selectionListChildren = filteredData.map(function (item, index) {
    return React.createElement(
      "div",
      { key: index, className: "selection-item" },
      React.createElement("span", { className: "serial-no" }, (index + 1) + "."), // Serial number added
      React.createElement("input", {
        type: "checkbox",
        value: item,
        onChange: function () {
          handleCheckboxChange(item);
        }
      }),
      React.createElement("label", null, item)
    );
  });

  return React.createElement(
    Layout, // Wrap the content inside Layout
    null,
    React.createElement("div",
      { className: "report-selection-container" },
      React.createElement("h1", null, "Select Ministries"),
      React.createElement(
        "p",
        null,
        "Refer to: ",
        React.createElement(
          "a",
          {
            href: "/resources/govt-orgs-and-depts",
            target: "_blank",
            rel: "noopener noreferrer"
          },
          "Govt Organizations & Departments"
        )
      ),
      React.createElement("input", {
        type: "text",
        placeholder: "Search...",
        value: searchTerm,
        onChange: function (e) {
          setSearchTerm(e.target.value);
        }
      }),
      React.createElement("div", { className: "selection-list" }, selectionListChildren),
      React.createElement(
        "div",
        { className: "next-button-container" },
        React.createElement("button", { onClick: handleNext }, "Next")
      )
    )
  );
};

export default MinistriesSelectionPage;
