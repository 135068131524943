import React from 'react';
import Layout from '../components/Layout';

const PricingPolicy = () => {
  return (
    <Layout>
      <div style={styles.pageContainer}>
        <h1 style={styles.heading}>Pricing Policy</h1>
        <p style={styles.text}><strong>Last updated:</strong> 22nd February 2025</p>
        <p style={styles.text}><strong>1. Subscription Plans:</strong> Various plans are available. Refer to our subscription page.</p>
        <p style={styles.text}><strong>2. Reports:</strong> Pricing is available in the reports section. No refunds for reports.</p>
        <p style={styles.text}><strong>3. Taxes:</strong> Prices include applicable taxes such as GST.</p>
        <p style={styles.text}><strong>4. Payment Methods:</strong> Secure payment channels are used. Payments imply agreement to our policies.</p>
        <p style={styles.text}><strong>5. Price Modifications:</strong> Prices may change at our discretion. Existing users will be notified.</p>
      </div>
    </Layout>
  );
};

const styles = {
  pageContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
  },
};

export default PricingPolicy;
