import React, { useState } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Layout from './components/Layout'; // Import the Layout component
import { useUserContext } from './UserContext'; // Import the UserContext

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState('weekly'); // Default to 'weekly' plan
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const currentUser = auth.currentUser;
  const { refreshUserDetails } = useUserContext(); // Corrected context refresh function

  const planPricing = {
    weekly: 499,
    monthly: 999,
    annual: 5999,
    combo: 34999, // Combo plan price
  };

  const handlePayment = async () => {
    if (currentUser) {
      try {
        // Create a new Razorpay order
        const amount = planPricing[selectedPlan] * 100; // Convert to paise
        const response = await fetch('/api/create-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount }),
        });

        const orderData = await response.json();

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: orderData.amount,
          currency: orderData.currency,
          name: 'Tender Manager',
          description: `Subscription Plan - ${selectedPlan === 'combo' ? 'Combo Plan (1-Year Tender Tracker + 5 L1 Support)' : selectedPlan} Plan`,
          order_id: orderData.id,
          handler: async (response) => {
            try {
              const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

              // Payment success - Verify the payment on server
              const verifyResponse = await fetch('/api/verify-payment', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  razorpay_order_id,
                  razorpay_payment_id,
                  razorpay_signature,
                }),
              });

              const verificationData = await verifyResponse.json();

              if (verificationData.status === 'success') {
                // Payment successful, update Firestore
                const phoneNumber = currentUser.phoneNumber;
                const docRef = doc(db, 'subscriptions', phoneNumber);
                const docSnap = await getDoc(docRef);

                const today = new Date();
                let newEndDate;

                // Calculate plan duration in milliseconds
                const planDuration =
                  selectedPlan === 'weekly' ? 7 : selectedPlan === 'monthly' ? 30 : 365;
                const planDurationMs = planDuration * 24 * 60 * 60 * 1000; // Convert days to milliseconds

                // Determine base date for new end date calculation
                let baseDate;
                if (docSnap.exists() && docSnap.data().endDate) {
                  const existingEndDateRaw = docSnap.data().endDate;
                  const existingEndDate = existingEndDateRaw.toDate
                    ? existingEndDateRaw.toDate()
                    : existingEndDateRaw instanceof Date
                    ? existingEndDateRaw
                    : new Date(existingEndDateRaw);

                  baseDate = existingEndDate.getTime() > today.getTime() ? existingEndDate : today;
                } else {
                  baseDate = today;
                }

                // Calculate new end date
                newEndDate = new Date(baseDate.getTime() + planDurationMs);

                // Save the updated subscription details
                await setDoc(
                  docRef,
                  {
                    endDate: newEndDate, // Store as Date object; Firestore converts to Timestamp
                    plan: selectedPlan,
                    startDate: today,
                    status: 'Active',
                  },
                  { merge: true }
                );

                // Refresh user context
                try {
                  await refreshUserDetails();
                } catch (refreshError) {
                  console.error('Error refreshing user context:', refreshError);
                }

                // Navigate to tender selection after successful subscription
                navigate('/tender-selection');
              } else {
                setErrorMessage('Payment verification failed. Please try again.');
              }
            } catch (error) {
              console.error('Error in payment handler:', error);
              setErrorMessage('An error occurred during payment processing.');
            }
          },
          prefill: {
            name: 'Your Name',
            email: 'your.email@example.com',
            contact: currentUser.phoneNumber,
          },
          theme: {
            color: '#F37254',
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error('Error in handlePayment:', error);
        setErrorMessage('Failed to subscribe. Please try again.');
      }
    } else {
      setErrorMessage('No authenticated user found. Please login first.');
    }
  };

  return (
    <Layout>
      <div>
        <h2>Select Subscription Plan</h2>
        <select
          value={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          <option value="weekly">Weekly Plan - ₹499</option>
          <option value="monthly">Monthly Plan - ₹999</option>
          <option value="annual">Annual Plan - ₹5999</option>
          <option value="combo">Combo Plan - ₹34,999 (1-Year Tender Tracker + 5 L1 Support)</option>
        </select>
        <button onClick={handlePayment}>Subscribe</button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    </Layout>
  );
};

export default SubscriptionPage;
