import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import NavBar from './NavBar';
import Footer from './Footer';
import './GovtOrgAndDepts.css';

const GovtOrgAndDepts = () => {
  // Data states (raw JSON)
  const [centralData, setCentralData] = useState([]);
  const [stateData, setStateData] = useState([]);

  // Hierarchical structures for display
  const [centralHierarchy, setCentralHierarchy] = useState([]);
  const [stateHierarchy, setStateHierarchy] = useState([]);

  // Loading state
  const [isLoading, setIsLoading] = useState(true);

  // --- Central Government Selection States ---
  const [selectedCentralMinistries, setSelectedCentralMinistries] = useState([]);
  const [selectedCentralDepartments, setSelectedCentralDepartments] = useState([]);
  const [selectedCentralOrganizations, setSelectedCentralOrganizations] = useState([]);

  // --- State Government Selection States ---
  const [selectedStateNames, setSelectedStateNames] = useState([]);
  const [selectedStateDepartments, setSelectedStateDepartments] = useState([]);
  const [selectedStateOrganizations, setSelectedStateOrganizations] = useState([]);

  // --- Expansion (Accordion) States for UI ---
  const [expandedCentralMinistries, setExpandedCentralMinistries] = useState({});
  const [expandedCentralDepartments, setExpandedCentralDepartments] = useState({});
  const [expandedStateNames, setExpandedStateNames] = useState({});
  const [expandedStateDepartments, setExpandedStateDepartments] = useState({});

  // --- Fetch Data on Mount (using Promise.all for parallel fetch) ---
  useEffect(() => {
    Promise.all([
      fetch('/central_data.json').then(response => response.json()),
      fetch('/state_data.json').then(response => response.json()),
    ])
      .then(([central, state]) => {
        setCentralData(central);
        setCentralHierarchy(groupCentralData(central));
        setStateData(state);
        setStateHierarchy(groupStateData(state));
        setIsLoading(false); // Done loading
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Even on error, stop showing loader
      });
  }, []);

  // --- Data Transformation Helpers ---
  // For central data: Group by Ministry → Departments → Organizations
  const groupCentralData = (data) => {
    const ministries = {};
    data.forEach(item => {
      const { Ministry, Department, Organization } = item;
      if (!ministries[Ministry]) ministries[Ministry] = {};
      if (!ministries[Ministry][Department]) ministries[Ministry][Department] = new Set();
      ministries[Ministry][Department].add(Organization);
    });
    return Object.keys(ministries).map(ministry => ({
      ministry,
      departments: Object.keys(ministries[ministry]).map(dept => ({
        department: dept,
        organizations: Array.from(ministries[ministry][dept])
      }))
    }));
  };

  // For state data: Group by State → Departments → Organizations
  const groupStateData = (data) => {
    const states = {};
    data.forEach(item => {
      const { State, Department, Organization } = item;
      if (!states[State]) states[State] = {};
      if (!states[State][Department]) states[State][Department] = new Set();
      states[State][Department].add(Organization);
    });
    return Object.keys(states).map(stateName => ({
      state: stateName,
      departments: Object.keys(states[stateName]).map(dept => ({
        department: dept,
        organizations: Array.from(states[stateName][dept])
      }))
    }));
  };

  // --- CENTRAL GOVERNMENT SELECTION HANDLERS (CASCADING) ---
  const handleCentralMinistryToggle = (ministry) => {
    const ministryData = centralHierarchy.find(item => item.ministry === ministry);
    if (selectedCentralMinistries.includes(ministry)) {
      setSelectedCentralMinistries(selectedCentralMinistries.filter(m => m !== ministry));
      if (ministryData) {
        const deptNames = ministryData.departments.map(d => d.department);
        setSelectedCentralDepartments(prev => prev.filter(d => !deptNames.includes(d)));
        const orgNames = ministryData.departments.reduce((acc, d) => acc.concat(d.organizations), []);
        setSelectedCentralOrganizations(prev => prev.filter(o => !orgNames.includes(o)));
      }
    } else {
      setSelectedCentralMinistries([...selectedCentralMinistries, ministry]);
      if (ministryData) {
        const deptNames = ministryData.departments.map(d => d.department);
        setSelectedCentralDepartments(prev => {
          const newDepts = deptNames.filter(d => !prev.includes(d));
          return [...prev, ...newDepts];
        });
        const orgNames = ministryData.departments.reduce((acc, d) => acc.concat(d.organizations), []);
        setSelectedCentralOrganizations(prev => {
          const newOrgs = orgNames.filter(o => !prev.includes(o));
          return [...prev, ...newOrgs];
        });
      }
    }
  };

  const handleCentralDeptToggle = (ministry, department) => {
    const ministryData = centralHierarchy.find(item => item.ministry === ministry);
    const deptData = ministryData
      ? ministryData.departments.find(d => d.department === department)
      : null;
    if (selectedCentralDepartments.includes(department)) {
      setSelectedCentralDepartments(selectedCentralDepartments.filter(d => d !== department));
      if (deptData) {
        const orgNames = deptData.organizations;
        setSelectedCentralOrganizations(prev => prev.filter(o => !orgNames.includes(o)));
      }
    } else {
      setSelectedCentralDepartments([...selectedCentralDepartments, department]);
      if (deptData) {
        const orgNames = deptData.organizations;
        setSelectedCentralOrganizations(prev => {
          const newOrgs = orgNames.filter(o => !prev.includes(o));
          return [...prev, ...newOrgs];
        });
      }
    }
  };

  const handleCentralOrgToggle = (org) => {
    if (selectedCentralOrganizations.includes(org)) {
      setSelectedCentralOrganizations(selectedCentralOrganizations.filter(o => o !== org));
    } else {
      setSelectedCentralOrganizations([...selectedCentralOrganizations, org]);
    }
  };

  // --- STATE GOVERNMENT SELECTION HANDLERS (CASCADING) ---
  const handleStateToggle = (stateName) => {
    const stateData = stateHierarchy.find(item => item.state === stateName);
    if (selectedStateNames.includes(stateName)) {
      setSelectedStateNames(selectedStateNames.filter(s => s !== stateName));
      if (stateData) {
        const deptNames = stateData.departments.map(d => d.department);
        setSelectedStateDepartments(prev => prev.filter(d => !deptNames.includes(d)));
        const orgNames = stateData.departments.reduce((acc, d) => acc.concat(d.organizations), []);
        setSelectedStateOrganizations(prev => prev.filter(o => !orgNames.includes(o)));
      }
    } else {
      setSelectedStateNames([...selectedStateNames, stateName]);
      if (stateData) {
        const deptNames = stateData.departments.map(d => d.department);
        setSelectedStateDepartments(prev => {
          const newDepts = deptNames.filter(d => !prev.includes(d));
          return [...prev, ...newDepts];
        });
        const orgNames = stateData.departments.reduce((acc, d) => acc.concat(d.organizations), []);
        setSelectedStateOrganizations(prev => {
          const newOrgs = orgNames.filter(o => !prev.includes(o));
          return [...prev, ...newOrgs];
        });
      }
    }
  };

  const handleStateDeptToggle = (stateName, department) => {
    const stateData = stateHierarchy.find(item => item.state === stateName);
    const deptData = stateData
      ? stateData.departments.find(d => d.department === department)
      : null;
    if (selectedStateDepartments.includes(department)) {
      setSelectedStateDepartments(selectedStateDepartments.filter(d => d !== department));
      if (deptData) {
        const orgNames = deptData.organizations;
        setSelectedStateOrganizations(prev => prev.filter(o => !orgNames.includes(o)));
      }
    } else {
      setSelectedStateDepartments([...selectedStateDepartments, department]);
      if (deptData) {
        const orgNames = deptData.organizations;
        setSelectedStateOrganizations(prev => {
          const newOrgs = orgNames.filter(o => !prev.includes(o));
          return [...prev, ...newOrgs];
        });
      }
    }
  };

  const handleStateOrgToggle = (org) => {
    if (selectedStateOrganizations.includes(org)) {
      setSelectedStateOrganizations(selectedStateOrganizations.filter(o => o !== org));
    } else {
      setSelectedStateOrganizations([...selectedStateOrganizations, org]);
    }
  };

  // --- Expansion Handlers for the Accordion UI ---
  const toggleCentralMinistryExpansion = (ministry) => {
    setExpandedCentralMinistries(prev => ({
      ...prev,
      [ministry]: !prev[ministry],
    }));
  };

  const toggleCentralDeptExpansion = (ministry, department) => {
    const key = `${ministry}-${department}`;
    setExpandedCentralDepartments(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const toggleStateExpansion = (stateName) => {
    setExpandedStateNames(prev => ({
      ...prev,
      [stateName]: !prev[stateName],
    }));
  };

  const toggleStateDeptExpansion = (stateName, department) => {
    const key = `${stateName}-${department}`;
    setExpandedStateDepartments(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // --- Export Handler ---
  const handleExport = () => {
    let exportData = [];
    let serial = 1;
    // For central government
    selectedCentralMinistries.forEach(ministry => {
      exportData.push({
        "S. No": serial++,
        Type: "Central Ministry",
        Name: ministry
      });
    });
    selectedCentralDepartments.forEach(dept => {
      exportData.push({
        "S. No": serial++,
        Type: "Central Department",
        Name: dept
      });
    });
    selectedCentralOrganizations.forEach(org => {
      exportData.push({
        "S. No": serial++,
        Type: "Central Organization",
        Name: org
      });
    });
    // For state government
    selectedStateNames.forEach(stateName => {
      exportData.push({
        "S. No": serial++,
        Type: "State",
        Name: stateName
      });
    });
    selectedStateDepartments.forEach(dept => {
      exportData.push({
        "S. No": serial++,
        Type: "State Department",
        Name: dept
      });
    });
    selectedStateOrganizations.forEach(org => {
      exportData.push({
        "S. No": serial++,
        Type: "State Organization",
        Name: org
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Data");
    XLSX.writeFile(workbook, "Selected_Govt_Entities.xlsx");
  };

  // --- Conditionally render a loading indicator ---
  if (isLoading) {
    return (
      <div className="page-container">
        <NavBar />
        <main className="content-container govtdeptandorg">
          <h1>Government Organizations &amp; Departments</h1>
          <div className="loader-container">
            {/* Spinner DIV */}
            <div className="spinner"></div>
            <p>Loading data... please wait.</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
  

  // --- Render the normal UI once data is loaded ---
  return (
    <div className="page-container">
      <NavBar />
      <main className="content-container govtdeptandorg">
        <h1>Government Organizations &amp; Departments</h1>
        <p>
          <em>
            Click anywhere on the header row to expand/collapse an item. The nested items are indented
            for clarity. Use the checkboxes to select an entire ministry, department, or individual
            organization.
          </em>
        </p>

        <div className="accordion-grid">
          {/* CENTRAL GOVERNMENT SECTION */}
          <section className="accordion-section">
            <h2>Central Government</h2>
            <div className="accordion-container">
              {centralHierarchy.map((ministryItem, idx) => (
                <div key={idx} className="accordion-item">
                  <div
                    className="accordion-header"
                    onClick={() => toggleCentralMinistryExpansion(ministryItem.ministry)}
                  >
                    <label className="clickable-label">
                      <input
                        type="checkbox"
                        checked={selectedCentralMinistries.includes(ministryItem.ministry)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleCentralMinistryToggle(ministryItem.ministry)}
                      />
                      <strong>{ministryItem.ministry}</strong>
                      <span className="toggle-icon">
                        {expandedCentralMinistries[ministryItem.ministry] ? '-' : '+'}
                      </span>
                    </label>
                  </div>
                  {expandedCentralMinistries[ministryItem.ministry] && (
                    <div className="accordion-content">
                      {ministryItem.departments.map((dept, dIdx) => {
                        const deptKey = `${ministryItem.ministry}-${dept.department}`;
                        return (
                          <div key={dIdx} className="department-item">
                            <div
                              className="department-header"
                              onClick={() => toggleCentralDeptExpansion(ministryItem.ministry, dept.department)}
                            >
                              <label className="clickable-label">
                                <input
                                  type="checkbox"
                                  checked={selectedCentralDepartments.includes(dept.department)}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() =>
                                    handleCentralDeptToggle(ministryItem.ministry, dept.department)
                                  }
                                />
                                {dept.department}
                                <span className="toggle-icon">
                                  {expandedCentralDepartments[deptKey] ? '-' : '+'}
                                </span>
                              </label>
                            </div>
                            {expandedCentralDepartments[deptKey] && (
                              <ul className="organization-list">
                                {dept.organizations.map((org, oIdx) => (
                                  <li key={oIdx}>
                                    <label className="clickable-label">
                                      <input
                                        type="checkbox"
                                        checked={selectedCentralOrganizations.includes(org)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleCentralOrgToggle(org)}
                                      />
                                      {org}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>

          {/* STATE GOVERNMENT SECTION */}
          <section className="accordion-section">
            <h2>State Government</h2>
            <div className="accordion-container">
              {stateHierarchy.map((stateItem, idx) => (
                <div key={idx} className="accordion-item">
                  <div
                    className="accordion-header"
                    onClick={() => toggleStateExpansion(stateItem.state)}
                  >
                    <label className="clickable-label">
                      <input
                        type="checkbox"
                        checked={selectedStateNames.includes(stateItem.state)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleStateToggle(stateItem.state)}
                      />
                      <strong>{stateItem.state}</strong>
                      <span className="toggle-icon">
                        {expandedStateNames[stateItem.state] ? '-' : '+'}
                      </span>
                    </label>
                  </div>
                  {expandedStateNames[stateItem.state] && (
                    <div className="accordion-content">
                      {stateItem.departments.map((dept, dIdx) => {
                        const deptKey = `${stateItem.state}-${dept.department}`;
                        return (
                          <div key={dIdx} className="department-item">
                            <div
                              className="department-header"
                              onClick={() => toggleStateDeptExpansion(stateItem.state, dept.department)}
                            >
                              <label className="clickable-label">
                                <input
                                  type="checkbox"
                                  checked={selectedStateDepartments.includes(dept.department)}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() => handleStateDeptToggle(stateItem.state, dept.department)}
                                />
                                {dept.department}
                                <span className="toggle-icon">
                                  {expandedStateDepartments[deptKey] ? '-' : '+'}
                                </span>
                              </label>
                            </div>
                            {expandedStateDepartments[deptKey] && (
                              <ul className="organization-list">
                                {dept.organizations.map((org, oIdx) => (
                                  <li key={oIdx}>
                                    <label className="clickable-label">
                                      <input
                                        type="checkbox"
                                        checked={selectedStateOrganizations.includes(org)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleStateOrgToggle(org)}
                                      />
                                      {org}
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>

        {/* Export Button */}
        <div className="fixed-buttons">
          <button
            className="download-button"
            onClick={handleExport}
            disabled={
              selectedCentralMinistries.length === 0 &&
              selectedCentralDepartments.length === 0 &&
              selectedCentralOrganizations.length === 0 &&
              selectedStateNames.length === 0 &&
              selectedStateDepartments.length === 0 &&
              selectedStateOrganizations.length === 0
            }
          >
            Download Selected Data
          </button>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default GovtOrgAndDepts;
