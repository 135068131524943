import React from 'react';
import Layout from '../components/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div style={styles.pageContainer}>
        <h1 style={styles.heading}>Privacy Policy</h1>
        <p style={styles.text}><strong>Last updated:</strong> 22nd February 2025</p>
        <p style={styles.text}>
          At Tender Managers, your privacy is of utmost importance. This policy outlines how we collect, use, and protect your personal data.
        </p>
        <p style={styles.text}><strong>1. Information We Collect:</strong> Personal information provided during registration, transaction details, etc.</p>
        <p style={styles.text}><strong>2. Use of Information:</strong> We use data to deliver services, process payments, run promotional content, and improve user experience.</p>
        <p style={styles.text}><strong>3. Security:</strong> We implement measures to protect your data but cannot guarantee absolute security.</p>
      </div>
    </Layout>
  );
};

const styles = {
  pageContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '10px',
  },
};

export default PrivacyPolicy;
