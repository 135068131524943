import React, { useEffect, useState } from 'react'; 
import './GovernmentPortals.css';
import NavBar from './NavBar';
import Footer from './Footer';

const GovernmentPortals = () => {
  const [centralPortals, setCentralPortals] = useState([]);
  const [statePortals, setStatePortals] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("December 8, 2024"); // Example default
  const [isLoading, setIsLoading] = useState(true); // <-- 1) loading state

  useEffect(() => {
    // Fetch both JSON files in parallel
    Promise.all([
      fetch('/central_portals.json').then((res) => res.json()),
      fetch('/state_portals.json').then((res) => res.json()),
    ])
      .then(([centralData, stateData]) => {
        setCentralPortals(centralData);
        setStatePortals(stateData);
        setIsLoading(false); // <-- 2) loading complete
      })
      .catch((error) => {
        console.error("Error fetching portals:", error);
        setIsLoading(false); // Even on error, we stop showing "loading"
      });
  }, []);

  // 3) If still loading, show a loader
  if (isLoading) {
    return (
      <div className="page-container">
        <NavBar />
        <main className="content-container government-portals">
          <h1>Government Procurement Portals</h1>
          <p>Loading portals...please wait.</p>
        </main>
        <Footer />
      </div>
    );
  }

  // Once loading is done, return real content
  return (
    <div className="page-container">
      <NavBar />
      <main className="content-container government-portals">
        <h1>Government Procurement Portals</h1>
        <p>
          Below is a curated list of central and state government portals where procurement activities
          take place. Use these portals to access tenders and bidding opportunities.
        </p>
        <p className="last-updated">Last Updated: {lastUpdated}</p>

        <div className="portals-grid">
          {/* Central Govt Portals Section */}
          <section className="portals-column">
            <h2>Central Government Portals ({centralPortals.length})</h2>
            <ul className="central-portals-list">
              {centralPortals.map((portal, index) => (
                <li key={index}>
                  <span className="serial-number">{index + 1}. </span>
                  <a
                    href={portal["Central Govt Portals"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {portal["Central Govt Portals"]}
                  </a>
                </li>
              ))}
            </ul>
          </section>

          {/* State Govt Portals Section */}
          <section className="portals-column">
            <h2>State Government Portals ({statePortals.length})</h2>
            <ul className="state-portals-list">
              {statePortals.map((portal, index) => (
                <li key={index} className="state-portal-item">
                  <span className="serial-number">{index + 1}. </span>
                  <strong>{portal["State Name"]}</strong>
                  <a
                    href={portal["Portal"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Portal
                  </a>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default GovernmentPortals;
