import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout"; // Import the Layout component
import gemCategories from "./data/gem_categories.json";
import "./ReportSelectionPage.css";

const GemCategoriesSelectionPage = function () {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Flatten groups to get all categories and filter by search term
  const getFilteredData = function () {
    var data = gemCategories.flatMap(function (group) {
      return Array.isArray(group.categories) ? group.categories : [];
    });
    return data.filter(function (item) {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const handleCheckboxChange = function (item) {
    setSelectedItems(function (prev) {
      return prev.includes(item)
        ? prev.filter(function (i) { return i !== item; })
        : prev.concat(item);
    });
  };

  const handleNext = function () {
    if (selectedItems.length === 0) {
      alert("Please select at least one option.");
      return;
    }
    navigate("/gem-details", { state: { selectedItems: selectedItems } });
  };

  var filteredData = getFilteredData();
  var selectionListChildren = filteredData.map(function (item, index) {
    return React.createElement(
      "div",
      { key: index, className: "selection-item" },
      React.createElement("span", { className: "serial-no" }, (index + 1) + "."), // Serial number added
      React.createElement("input", {
        type: "checkbox",
        value: item,
        onChange: function () { handleCheckboxChange(item); }
      }),
      React.createElement("label", null, item)
    );
  });

  return React.createElement(
    Layout, // Wrap the content inside Layout
    null,
    React.createElement("div",
      { className: "report-selection-container" },
      React.createElement("h1", null, "Select GeM Categories"),
      React.createElement(
        "p",
        null,
        "Refer to: ",
        React.createElement(
          "a",
          {
            href: "/resources/gem-categories",
            target: "_blank",
            rel: "noopener noreferrer"
          },
          "GeM Categories"
        )
      ),
      React.createElement("input", {
        type: "text",
        placeholder: "Search...",
        value: searchTerm,
        onChange: function (e) { setSearchTerm(e.target.value); }
      }),
      React.createElement("div", { className: "selection-list" }, selectionListChildren),
      React.createElement(
        "div",
        { className: "next-button-container" },
        React.createElement("button", { onClick: handleNext }, "Next")
      )
    )
  );
};

export default GemCategoriesSelectionPage;
