// src/components/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout'; // Import the Layout component
import ClientCarousel from './ClientCarousel'; // Import the carousel
import './HomePage.css';

function HomePage() {
  return (
    <Layout>
      <div className="home-page">
        <header className="hero-section">
          <img
            src={`${process.env.PUBLIC_URL}/images/tm logo cream background.png`}
            alt="Left Logo"
            className="logo-left"
          />
          <div className="text-content">
            <h1>Welcome to Tender Managers</h1>
            <p>Your go-to platform for tender management and insights.</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/tm logo cream background.png`}
            alt="Right Logo"
            className="logo-right"
          />
        </header>

        {/* New Resources Section */}
        <section className="resources-section">
          <h2>Explore Our Resources</h2>
          <div className="resources-grid">
            <div className="resource-card">
              <h3>All Gem Categories</h3>
              <p>Browse comprehensive categories for tenders.</p>
              <Link to="/resources/gem-categories">Learn More</Link>
            </div>
            <div className="resource-card">
              <h3>Government Portals</h3>
              <p>Access government portals for tender opportunities.</p>
              <Link to="/resources/government-portals">Learn More</Link>
            </div>
            <div className="resource-card">
              <h3>Govt Departments</h3>
              <p>Explore central and state government departments.</p>
              <Link to="/resources/govt-orgs-and-depts">Learn More</Link>
            </div>
            <div className="resource-card">
              <h3>Procurement Rules</h3>
              <p>Discover consolidated procurement rules.</p>
              <Link to="/resources/procurement-rules">Learn More</Link>
            </div>
          </div>
        </section>

        {/* Client Carousel Section (Desktop only) */}
        <div className="carousel-section">
          <ClientCarousel />
        </div>

        <main className="content">
          <section className="company-description">
            <h2>What We Do</h2>
            <p>
              We provide comprehensive solutions for managing tenders, helping businesses streamline their processes and achieve better results. With our expertise, you can simplify your tender management and focus on what matters most.
            </p>
          </section>
          <section className="contact-us">
            <h2>Contact Us</h2>
            <p>Email: sales@gemmanagers.com</p>
            <p>Phone: +91-6398896398</p>
          </section>
        </main>
      </div>
    </Layout>
  );
}

export default HomePage;
