import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { TextField, Button } from '@mui/material';
import moment from 'moment';
import Layout from './components/Layout';
import { db, auth } from './firebaseConfig';
import { doc, setDoc, deleteDoc, getDocs, collection, writeBatch } from 'firebase/firestore';
import Highlighter from 'react-highlight-words'; // <-- Import the Highlighter
import './AppPage.css';

function App() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState('');
  const [sortModel, setSortModel] = useState([{ field: 'SNo', sort: 'asc' }]);
  const [customPage, setCustomPage] = useState('');
  const [favorites, setFavorites] = useState([]);

  // Fetch user's favorite GeM tenders
  const fetchFavorites = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;
    const favCollectionRef = collection(db, 'users', userId, 'favoriteGeMTenders');
    const favDocs = await getDocs(favCollectionRef);
    setFavorites(favDocs.docs.map(doc => doc.id));
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  // Fetch data from your backend
  const fetchData = useCallback(() => {
    const params = new URLSearchParams({
      page: page + 1,
      limit: pageSize,
      search,
      sortField: sortModel[0]?.field || 'SNo',
      sortOrder: sortModel[0]?.sort || 'asc',
    });

    const apiUrl = process.env.REACT_APP_API_URL || 'https://webapptest1-783744cf01e6.herokuapp.com';

    fetch(`${apiUrl}/api/data?${params.toString()}`)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setTotal(result.total);
      })
      .catch((error) => console.error('Error:', error));
  }, [page, pageSize, search, sortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Pagination handlers
  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  // Search box handler
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  // Sort handler
  const handleSortModelChange = (model) => setSortModel(model);

  // "Go to Page" handler
  const handleCustomPageChange = () => {
    const parsedPage = parseInt(customPage, 10);
    const maxPage = Math.ceil(total / pageSize) - 1;
    if (parsedPage >= 1 && parsedPage <= maxPage + 1) {
      setPage(parsedPage - 1);
    } else {
      alert(`Please enter a page between 1 and ${maxPage + 1}`);
    }
  };

  // Helper to sanitize the bid number for Firestore
  const sanitizeBidNumber = (bidNumber) => bidNumber.replace(/\//g, '-');

  // Toggle favorite for a particular row
  const toggleFavorite = async (tender) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        alert('Please log in to mark favorites.');
        return;
      }
      const sanitizedBidNumber = sanitizeBidNumber(tender.BidNumber);
      const favRef = doc(db, 'users', userId, 'favoriteGeMTenders', sanitizedBidNumber);

      if (favorites.includes(sanitizedBidNumber)) {
        // Unmark
        await deleteDoc(favRef);
        setFavorites(prev => prev.filter(bid => bid !== sanitizedBidNumber));
      } else {
        // Mark as favorite
        if (favorites.length >= 15) {
          alert('You can only mark up to 15 favorites.');
          return;
        }
        await setDoc(favRef, {
          bidNumber: tender.BidNumber,
          items: tender.Items,
          district: tender.District,
          bidType: tender.BidType,
          markedDate: new Date(),
        });
        setFavorites(prev => [...prev, sanitizedBidNumber]);
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  // Bulk Unmark All for GeM favorites
  const unmarkAllGemFavorites = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || favorites.length === 0) return;
      const batch = writeBatch(db);
      favorites.forEach((bid) => {
        batch.delete(doc(db, 'users', userId, 'favoriteGeMTenders', bid));
      });
      await batch.commit();
      setFavorites([]);
    } catch (error) {
      console.error('Error unmarking all favorites:', error);
    }
  };

  // Split search keywords (for highlighting)
  const searchKeywords = search.split(',').map(word => word.trim()).filter(Boolean);

  // Define columns with minimal changes to enable highlighting
  const columns = [
    {
      field: 'favoriteAction',
      headerName: 'Favorite',
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => toggleFavorite(params.row)}
          style={{
            backgroundColor: favorites.includes(sanitizeBidNumber(params.row.BidNumber)) ? 'yellow' : 'lightgray',
          }}
        >
          {favorites.includes(sanitizeBidNumber(params.row.BidNumber)) ? 'Unmark' : 'Favorite'}
        </button>
      ),
    },
    { field: 'SNo', headerName: 'S No', width: 90 },
    // Highlight 'State'
    {
      field: 'State',
      headerName: 'State',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    // Highlight 'District'
    {
      field: 'District',
      headerName: 'District',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    // Highlight 'BidNumber'
    {
      field: 'BidNumber',
      headerName: 'Bid Number',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    // Highlight 'Items' (with minimal styling to avoid overflow)
    {
      field: 'Items',
      headerName: 'Items',
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div style={{ wordWrap: 'break-word', display: 'block' }}>
          <Highlighter
            searchWords={searchKeywords}
            textToHighlight={params.value || ''}
            highlightStyle={{ backgroundColor: 'yellow' }}
          />
        </div>
      ),
    },
    { field: 'Quantity', headerName: 'Quantity', width: 100 },
    // Highlight 'BidType'
    {
      field: 'BidType',
      headerName: 'Bid Type',
      width: 120,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    // Highlight 'Ministry'
    {
      field: 'Ministry',
      headerName: 'Ministry',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    // Highlight 'Department'
    {
      field: 'Department',
      headerName: 'Department',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Highlighter
          searchWords={searchKeywords}
          textToHighlight={params.value || ''}
          highlightStyle={{ backgroundColor: 'yellow' }}
        />
      ),
    },
    {
      field: 'BidURL',
      headerName: 'Bid URL',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ wordWrap: 'break-word', display: 'block', maxWidth: '200px' }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.StartDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.EndDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
  ];

  const totalPages = Math.ceil(total / pageSize);
  const currentPage = page + 1;

  return (
    <Layout>
      <div className="gem-bids-container">
        <h1>GeM Bids</h1>
        <h3>GeM Bids Updated as on 24.03.2025</h3>

        {/* Combined Row for Search, Go to Page, and Unmark All */}
        <div
          className="search-controls"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <div
            className="search-row"
            style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem' }}
          >
            <TextField
              label="Search"
              variant="outlined"
              className="search-box"
              value={search}
              onChange={handleSearchChange}
              style={{ width: '300px' }}
              helperText="Enter keywords separated by commas (e.g., chair, table, CCTV)"
            />
            <div>
              <TextField
                label="Go to Page"
                variant="outlined"
                className="goto-page-box"
                value={customPage}
                onChange={(e) => setCustomPage(e.target.value)}
                style={{ marginRight: '0.5rem', width: '150px' }}
              />
              <Button variant="contained" onClick={handleCustomPageChange}>
                Go
              </Button>
            </div>
          </div>
          <Button
            variant="contained"
            className="unmark-button"
            onClick={unmarkAllGemFavorites}
          >
            Unmark All GeM Favorites
          </Button>
        </div>

        <p>
          Page {currentPage} of {totalPages} (Showing {pageSize} entries per page)
        </p>

        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            rows={data}
            columns={columns}
            rowCount={total}
            pagination
            paginationMode="server"
            page={page}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[25, 50, 100]}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowId={(row) => row._id || row.SNo}
            autoHeight
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

// Custom Toolbar without the export button
function CustomToolbar() {
  return <GridToolbarContainer />;
}

export default App;
