// LoginPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firebase, auth } from './firebaseConfig';
import * as firebaseui from 'firebaseui';
import Layout from './components/Layout';
import './LoginPage.css'; // Make sure this CSS file exists and is in the correct path

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const uiConfig = {
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'IN',
          recaptchaParameters: {
            type: 'image',
            size: 'invisible',
            badge: 'bottomleft',
          },
        },
      ],
      // No tosUrl / privacyPolicyUrl => disclaimers won't appear
      tosUrl: '',
      privacyPolicyUrl: '',
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          console.log('User signed in successfully:', authResult.user);
          navigate('/subscription-check');
          return false; // Prevent redirect
        },
        uiShown: () => {
          console.log('Firebase UI rendered');
        },
      },
    };

    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);

    return () => {
      ui.reset();
    };
  }, [navigate]);

  return (
    <Layout>
      {/* Use a background color consistent with your site (e.g. #e5ebe3) */}
      <div className="login-background">
        <div className="login-page-container">
          <div className="login-card">
            <h2>Tender Managers</h2>
            <p className="login-subtitle">
              Please Log in to Continue
            </p>

            {/* FirebaseUI container */}
            <div id="firebaseui-auth-container"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
